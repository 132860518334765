import React from 'react';
import {
  Header,
  Page,
} from '@backstage/core-components';
import { FetchCosts } from '../AzureCostFetchComponent';
import { useEntity } from '@backstage/plugin-catalog-react';




const Title = () =>
{
  const { entity } = useEntity();
  const tagValue = entity.metadata.annotations?.["azure.costs.tagValue"]

  return <text>Azure costs {tagValue}</text>
}

const HowTo = () => 
{

  return  <text>To enable azure cost you need to specify "azure.costs.tagName" and "azure.costs.tagValue" in the annotiations</text>
}


export const AzureCostComponent = () => 
(

  <Page themeId="tool">
    <Header title={<Title></Title>} subtitle={ AzureCostEnabled() ? ( <FetchCosts></FetchCosts>) : (<HowTo></HowTo>)} >
    </Header>
  </Page>
);

const AzureCostEnabled: () => boolean = () => {
  const { entity } = useEntity();
  return entity?.metadata?.annotations?.hasOwnProperty("azure.costs.tagValue") ?? false;
};

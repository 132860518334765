

import React, { useState, useEffect } from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { Divider } from '@mui/material';

import TextField from '@mui/material/TextField';



const labelStyles = {
  color: 'white', // Set the label color to white
};

interface HistoryApiResponse {
  Service: {
      Name : string,
      Stages: Stage[]      
  }[]
}

interface Stage {

      Name : string
      LastDeploy: Date
      LastDeployState: string,
      LastDeployUrl: string,
      ArtifactDate: string,
      Deployments: Deployment[]

}

interface Deployment {

      Url : string,
      DeployTime: Date
      DeployState: string
}


export interface Configuration {
    Stages : {
        Name : string
    }[],
    Segments: {
        Name: string;
        Stages: string [];
    }[]
}






function calculateHoursAgo(date: string | undefined): string {


    if (date === undefined) {
        return "N/A"; // Or any other suitable value to indicate that the date is undefined
      }
    
      const currentTimestamp = Date.now(); // Current timestamp in milliseconds
      const savedDate = Date.parse(date); // Convert the input string to a timestamp in milliseconds
      const timeDifferenceInMilliseconds = currentTimestamp - savedDate; // Calculate the time difference in milliseconds
      const hoursAgo = timeDifferenceInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
    
      return Math.round(hoursAgo).toString(); // Round to the nearest whole number of hours
  }

  function toLocalTimeString(date: string): string{
    const parsedDate = new Date(Date.parse(date));

    // Convert to local time and return as a string
    return parsedDate.toLocaleString();
  }

  function  statusStyle(deployState: string) : string {
    return deployState === "failed" ? "red" : "green";
  }

  export  const HomePage = () => {
    const [data, setData] = useState<HistoryApiResponse>();
    const [configData, setConfigData] = useState<Configuration>();
    const [searchQuery, setSearchQuery] = useState<string>('');
  
    const apiUrl = 'https://emdevopsnotifications.azurewebsites.net/api/azuredevopsreleasehistory?clientId=blobs_extension';
    const configApiUrl = 'https://emdevopsnotifications.azurewebsites.net/api/configuration?clientId=blobs_extension';
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(apiUrl);
          const config = await fetch(configApiUrl);
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const jsonData = await response.json();
          const configJsonData = await config.json();
          setData(jsonData);
          setConfigData(configJsonData);
          console.log(jsonData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
  
    const filteredServices = data?.Service.filter(service =>
      service.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );


  
    return (
      <div>

          <TextField
            label="Search by Service Name"
            variant="outlined"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            style={labelStyles}
          />

  
        <TableContainer component={Paper}>

            <TableHead>
              <TableRow>
                <TableCell><h2>Service</h2></TableCell>
                {configData?.Segments.map(segment => (
                  <TableCell key={segment.Name}><b>{segment.Name}</b></TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredServices?.map(service => (
                <TableRow
                  key={service.Name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{service.Name}</TableCell>
                  {configData?.Segments.map(segment => {
                    const stage = service.Stages.find(stage => stage.Name === segment.Name);
                    return (
                      <TableCell key={segment.Name}>
                        {stage?.LastDeployState ? (
                          <>
                            <b>Status:</b> <span style={{color: statusStyle(stage.LastDeployState)}}>{stage.LastDeployState || '-'}</span><br />
                            <b>Last Deploy:</b> {toLocalTimeString(stage.LastDeploy?.toString()) || '-'}<br />
                            <b>Artifact age:</b> {calculateHoursAgo(stage.ArtifactDate) + " Hours ago" || '-'}<br />
                            <b>Deployment:</b> <a href={stage.LastDeployUrl} target="_blank">Pipeline Link</a><br />
                            <Accordion>
                              <AccordionSummary
                                aria-controls="panel1a-content"
                                id={stage.LastDeployUrl}
                                style={{ maxHeight: '20px' }} 
                              >
                                <Typography>History</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                <Divider></Divider>
                                {stage.Deployments.map((deployment, index) => (
                                    <div key={index}>
                                        <b>Release Date:</b> {toLocalTimeString(deployment.DeployTime.toString())}<br />
                                        <b>Status:</b><span style={{color: statusStyle(deployment.DeployState)}}>{deployment.DeployState}</span> <br />
                                        <b>Deployment:</b> <a href={stage.LastDeployUrl} target="_blank">Pipeline Link</a>
                                        <Divider></Divider>
                                    </div>
                                    ))}
                               
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>

        </TableContainer>
      </div>
    );
  }
import React from 'react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { fetchApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { useEntity } from '@backstage/plugin-catalog-react';

import { discoveryApiRef } from '@backstage/core-plugin-api';



type Costs = 
{
  totalMonthlyCost: number
  totalMonthlyCostForecast: number
  totalYearlyCost: number
}

type CostProps = {
  TotalMonthlyCost: Costs
}

export const Costs = ({ TotalMonthlyCost }: CostProps) => {

  return <><h3>€ {TotalMonthlyCost.totalMonthlyCost} used in last billing month</h3> <h3>€ {TotalMonthlyCost.totalYearlyCost} yearly costs based on last billing month</h3> <h3>€ {TotalMonthlyCost.totalMonthlyCostForecast} forecast for next billing month</h3> </>;
};

export const FetchCosts = () => {

  const discoveryApi = useApi(discoveryApiRef);

  const { entity } = useEntity();

  const tagName = entity.metadata.annotations?.["azure.costs.tagName"]
  const tagValue = entity.metadata.annotations?.["azure.costs.tagValue"]


  const { fetch } = useApi(fetchApiRef);
  const { value, loading, error } = useAsync(async (): Promise<Costs> => {
    const response = await fetch(`${await discoveryApi.getBaseUrl('azure-cost')}/resource-group/${tagName}/${tagValue}`);
    const data = await response.json();
    return data;
  });


  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }
  return <Costs TotalMonthlyCost={value || { totalMonthlyCost: 0, totalMonthlyCostForecast: 0, totalYearlyCost: 0 }} />;
};



